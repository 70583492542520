import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  commentCard: {
    width: "100%",
    backgroundColor: '#ddd',
  },
  paper: {
    margin: 'auto',
    maxWidth: 800,
  },
  rightSide: {
    flexGrow: 1,
  },
}));

const dateOptions = { 
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function hashText(text) {
  let x = 0;
  text = text.toUpperCase();
  for(let i=0; i<Math.min(5, text.length); i++) {
    x += text.charCodeAt(i) - 65;
    x *= 31;
  }
  return x;
}

function randomColor(seedText) {
  let hash = hashText(seedText);
  let x = Math.sin(hash) * 10000;
  x -= Math.floor(x);
  let hex = Math.floor(x * 0xFFFFFF);
  let color = "#" + hex.toString(16);
  return color;
}

export default function Comment(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Card className={classes.commentCard} >
        <CardHeader
          avatar={
            <Avatar id="av" aria-label="Username"
              style={{ backgroundColor: randomColor(props.comment.username) }}
              className={classes.avatar}>{props.comment.username.charAt(0).toLocaleUpperCase()}
            </Avatar>
          }
          disableTypography={true}
          title={
            <Grid container direction="row">
              <Typography variant="h7" component="h4" color="textPrimary">
                {props.comment.username}
              </Typography>
              <Typography variant="h7" component="body" color="textSecondary" align="right" className={classes.rightSide}>
                {new Date(props.comment.creationTime).toLocaleString("en-IN", dateOptions)}
              </Typography>
            </Grid>
          }
          subheader={
            <Typography variant="h7" component="body" color="textPrimary">
              {props.comment.text}
            </Typography>
          }
        />
      </Card>
    </Paper>
  );
}
