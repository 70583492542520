import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import TextField from '@material-ui/core/TextField';

import { BASE_URL } from '../config';
import Comment from './Comment';

const useStyles = makeStyles(theme => ({
  postCard: {
    width: "100%",
  },
  paper: {
    margin: 'auto',
    maxWidth: 800,
  },
  rightSide: {
    flexGrow: 1,
    paddingTop: 10,
  },
  commentBox: {
    flexGrow: 1,
    marginLeft: 20,
    marginTop: 20,
  },
  postImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const dateOptions = { 
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function hashText(text) {
  let x = 0;
  text = text.toUpperCase();
  for(let i=0; i<Math.min(5, text.length); i++) {
    x += text.charCodeAt(i) - 65;
    x *= 31;
  }
  return x;
}

function randomColor(seedText) {
  let hash = hashText(seedText);
  let x = Math.sin(hash) * 10000;
  x -= Math.floor(x);
  let hex = Math.floor(x * 0xFFFFFF);
  let color = "#" + hex.toString(16);
  return color;
}

export default function Post(props) {
  const classes = useStyles();

  const [commentText, setCommentText] = React.useState("");
  const [post, setPost] = React.useState(props.post);
  const [usersVote, setUsersVote] = React.useState(null);
  const [commentsSection, setCommentsSection] = React.useState({visible: false, comments: []});

  React.useEffect(async () => {
    let postId = `${post.username}%23${post.creationTime}`;
    let getVoteResponse = await fetch(`${BASE_URL}/vote?post=${postId}&username=${props.currentUser}`);
    let vote = await getVoteResponse.text();
    setUsersVote(vote);
    let getCommentsResponse = await fetch(`${BASE_URL}/comments?post=${postId}`);
    let allComments = await getCommentsResponse.json();
    setCommentsSection({
      visible: false,
      comments: allComments,
    });
  }, []);

  const addVote = async (type) => {
    if (props.currentUser) {
      let postId = `${post.username}#${post.creationTime}`;
      let vote = {
        post: postId,
        username: props.currentUser,
        type: type,
      };
      const response = await fetch(`${BASE_URL}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vote),
      });
      const responsePost = await response.json();
      setPost(responsePost);
      setUsersVote(type);
    } else {
      alert("You need to log in before voting.")
    }
  }

  const postComment = async () => {
    if (props.currentUser) {
      let postId = `${post.username}#${post.creationTime}`;
      let comment = {
        post: postId,
        username: props.currentUser,
        text: commentText,
      };
      await fetch(`${BASE_URL}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(comment),
      });
      showComments();
      setCommentText("");
    } else {
      alert("You need to log in before commenting.")
    }
  }

  const showComments = async () => {
    let postId = `${post.username}%23${post.creationTime}`;
    let getCommentsResponse = await fetch(`${BASE_URL}/comments?post=${postId}`);
    let allComments = await getCommentsResponse.json();
    setCommentsSection({
      visible: true,
      comments: allComments,
    });
  }

  return (
    <Paper className={classes.paper}>
      <Card className={classes.postCard} >
        <CardHeader
          avatar={
            <Avatar id="av" aria-label="Username"
              style={{ backgroundColor: randomColor(post.username) }}
              className={classes.avatar}>{post.username.charAt(0).toLocaleUpperCase()}
            </Avatar>
          }
          title={post.username}
          titleTypographyProps={{component: "h3", variant:"h6"}}
          subheader={new Date(post.creationTime).toLocaleString("en-IN", dateOptions)}
        />
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item wrap="nowrap">
              <Typography variant="h5" noWrap>
                {post.title}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h7" component="body" color="textPrimary">
                {post.content}
              </Typography>
            </Grid>

            <Grid container item className={classes.postImage}>
              {post.attachments && post.attachments.map((attachment) => {
                console.log("Attachment:", attachment);
                return (<Grid item>
                  <img src={`${BASE_URL}/attachment?id=${attachment}`} width="100%"/>
                </Grid>);
              })
              }
            </Grid>

            <Grid item>
              <hr/>
            </Grid>
            
            <Grid container item spacing={3} marginTop={2} xs="12" direction="row" wrap="nowrap">
              <Button variant="text" color={usersVote === "UP" ? "secondary" : "inherit"}
                startIcon={<ArrowUpward/>} onClick={() => addVote("UP")}>
                {post.votes.up}
              </Button>
              <Button variant="text" color={usersVote === "DOWN" ? "secondary" : "inherit"}
                startIcon={<ArrowDownward/>} onClick={() => addVote("DOWN")}>
                {post.votes.down}
              </Button>
              <Button variant="text" color="inherit" onClick={showComments} className={classes.rightSide}>
                View Comments ({commentsSection.comments.length})
              </Button>
            </Grid>

            <Grid item>
              <hr/>
            </Grid>
            
            <Grid container item spacing={3} marginTop={2} xs="12" direction="row" wrap="nowrap">
              <TextField variant="outlined" margin="normal" fullWidth multiline minRows={1}
                  value={commentText} label="Enter a comment" name="comment"
                  className={classes.commentBox} onChange={(e) => setCommentText(e.target.value)}/>
              <Button variant="text" color="inherit" onClick={postComment} startIcon={<SendIcon/>}/>
            </Grid>

            {commentsSection.visible &&
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <hr/>
                </Grid>
                {commentsSection.comments && commentsSection.comments.length > 0 ?
                  commentsSection.comments.sort((a, b) => {return a.creationTime-b.creationTime;})
                                          .map((comment, i) => { 
                    return (
                      <Grid item key={i}>
                        <Comment comment={comment} />
                      </Grid>
                    );
                  })
                  :
                  <Typography align="center" variant="h7" component="body" color="textSecondary">
                    No comments on this post yet.
                  </Typography>
                }
              </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
