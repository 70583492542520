import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Post from './Post';
import { BASE_URL } from '../config';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: theme.spacing(10),
  },
  postContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [posts, setPosts] = React.useState([]);

  React.useEffect(async () => {
    let getPostsResponse = await fetch(`${BASE_URL}/posts`);
    let allPosts = await getPostsResponse.json();
    console.log(posts);
    setPosts(allPosts);
  }, []);

  return (
    <Grid container direction="column" className={classes.mainContainer}>
      {
        posts.sort((a, b) => {return b.creationTime-a.creationTime;}).map((post, i) => { 
          return (
            <Grid item key={i} className={classes.postContainer}>
              <Post post={post} currentUser={props.currentUser} />
            </Grid>
          );
        })
      }
    </Grid>
  );
}
