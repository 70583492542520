import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { BASE_URL } from '../config';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreatePost(props) {
  const classes = useStyles();

  const [chosenFiles, setChosenFiles] = React.useState(null);

  const handleSubmitClick = async () => {
    let attachments = [];
    if(chosenFiles && chosenFiles.length > 0) {
      attachments = await uploadFiles();
    }
    const post = {
      username: props.currentUser,
      content: document.getElementById("content").value,
      title: document.getElementById("title").value,
      attachments: attachments,
    };
    await fetch(`${BASE_URL}/posts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(post),
    });
    props.onClose();
    window.location.reload();
  }

  const uploadFiles = async () => {
    let fileIds = await Promise.all(
      Array.from(chosenFiles).map(async (file) => {
        let type = file.type;
        let result = await fetch(`${BASE_URL}/attachment`, {
          method: 'POST',
          headers: {'file-type': type},
          body: file,
        });
        let attachmentId = await result.text();
        return attachmentId;
      })
    );
    return fileIds;
  } 

  const filesChosen = () => {
    let fileObject = document.getElementById("attach-image-file");
    setChosenFiles(fileObject.files);
  }

  return (
    <Dialog open={props.show} onClose={props.onClose} aria-labelledby="create-new-post">
      <div className={classes.paper}> 
        <Typography component="h1" variant="h5">
          Create New Post
        </Typography>
        <Container className={classes.form} noValidate>
          <TextField variant="outlined" margin="normal" required fullWidth
            id="title" label="Title" name="title"/>
          <TextField variant="outlined" margin="normal" required fullWidth multiline minRows={3}
            id="content" label="Content" name="content" />
          <Container>
            <input accept="image/*" style={{ display: 'none' }} multiple
              id="attach-image-file" type="file" onChange={filesChosen}
            />
            <label htmlFor="attach-image-file">
              <Button variant="contained" component="span">
                Attach Images
              </Button>
              {chosenFiles && chosenFiles.length > 0 &&
                <Typography component="a" variant="p" align="right">
                  &nbsp; &nbsp; {chosenFiles.length} files chosen.
                </Typography>
              }
            </label> 
          </Container>
          <Button variant="contained" color="primary" onClick={handleSubmitClick} className={classes.submit}>
            Create Post
          </Button>
        </Container>
      </div>
    </Dialog>
  );
}
