import React from 'react';
import Cookies from 'universal-cookie';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBox';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

import CreatePost from './CreatePost';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function TopBar(props) {
  const classes = useStyles();

  const [showCreatePostModal, setShowCreatePostModal] = React.useState(false);
  const [showLoginBox, setShowLoginBox] = React.useState(false);

  const onCreatePostModalClose = () => setShowCreatePostModal(false);
  const openCreatePostModal = () => {
    if (props.currentUser) setShowCreatePostModal(true);
    else alert("You need to log in before creating a new post.");
  }
  
  const onLoginBoxClose = () => setShowLoginBox(false);
  const openLoginBox = () => setShowLoginBox(true);

  const login = () => {
    onLoginBoxClose();
    let newUser = document.getElementById("username").value;
    props.setCurrentUser(newUser);
    const cookies = new Cookies();
    cookies.set('currentUser', newUser, {maxAge: 24*60*60});
  }

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Grid container direction="row" className={classes.title}>
          <Typography variant="h6">
            {props.currentUser ? `Logged in as: ${props.currentUser}` : `Not Logged in`}
          </Typography>
          <Button variant="outlined" color="inherit"
                  className={classes.menuButton} onClick={openLoginBox}>
            {props.currentUser ? "Change Username" : "Login"}
          </Button>
        </Grid>
        <Grid container direction="row" className={classes.title}>
          <Typography variant="h6" className={classes.title}>
            dryairship's Social Network
          </Typography>
        </Grid>
        <Grid container direction="row-reverse">
          <Button variant="outlined" color="inherit" startIcon={<AddIcon/>} 
                  className={classes.menuButton} onClick={openCreatePostModal}>
            Create New Post
          </Button>
        </Grid>
      </Toolbar>
      <CreatePost show={showCreatePostModal} onClose={onCreatePostModalClose} currentUser={props.currentUser}/>
      <Dialog open={showLoginBox} onClose={onLoginBoxClose}>
        <DialogTitle>Log In</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a username:
          </DialogContentText>
          <TextField autoFocus margin="dense" id="username" label="Username"
            type="text" fullWidth variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onLoginBoxClose}>Cancel</Button>
          <Button onClick={login}>Log In</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
