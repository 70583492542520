import './App.css';
import React from 'react';
import Dashboard from './components/Dashboard';
import TopBar from './components/TopBar';
import Cookies from 'universal-cookie';

function App() {

  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    const cookies = new Cookies();
    const user = cookies.get("currentUser");
    if(user) setCurrentUser(user);
  }, []);

  return (
    <React.Fragment>
      <TopBar currentUser={currentUser} setCurrentUser={setCurrentUser}/>
      <Dashboard currentUser={currentUser}/>
    </React.Fragment>
  );
}

export default App;
